<template>
  <div>
    <Modal :options="{esc:false}">
      <form @submit.prevent="guardar_catalogo">
        <div class="title">Catálogo</div>
        <div class="body">
          <div class="row form-group">
            <label for="nombre" class="col-form-label col-sm-12 col-lg-3">Nombre</label>
            <div class="col-sm-12 col-lg-9"><input v-model="tmp_catalogo.nombre" type="text" name="nombre" id="nombre" class="form-control"></div>
          </div>
          <div class="row form-group">
            <label for="codigo" class="col-form-label col-sm-12 col-lg-3">Codigo</label>
            <div class="col-sm-12 col-lg-9"><input v-model="tmp_catalogo.codigo" type="text" name="codigo" id="codigo" class="form-control"></div>
          </div>
          <div class="row form-group">
            <label for="descripcion" class="col-form-label col-sm-12 col-lg-3">Descripción</label>
            <div class="col-sm-12 col-lg-9"><input v-model="tmp_catalogo.descripcion" type="text" name="descripcion" id="descripcion" class="form-control"></div>
          </div>
          <div class="row form-group">
            <label for="estatus" class="col-form-label col-sm-12 col-lg-3">Estatus</label>
            <div class="col-sm-12 col-lg-9">
              <select v-model="tmp_catalogo.estatus" name="estatus" id="estatus" class="form-control">
                <option value="1">Activo</option>
                <option value="0">Inactivo</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 my-3 text-right"><button class="btn btn-secondary" type="button" @click="agragar_opcion_form=true">Agregar opción</button></div>
          </div>
          <div class="row">
            <div class="col-sm-12 catalogo_opciones">
              <table>
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Valor</th>
                    <th>Estatus</th>
                    <th>Opciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="agragar_opcion_form">
                    <td><input v-model="tmp_opcion.nombre" type="text" name="nombre" id="nombre" class="form-control"></td>
                    <td><input v-model="tmp_opcion.valor" type="text" name="valor" id="valor" class="form-control"></td>
                    <td><select v-model="tmp_opcion.estatus" name="estatus" id="estatus" class="form-control">
                      <option value="1">Activo</option>
                      <option value="0">Inactivo</option>
                    </select></td>
                    <td>
                      <button class="btn btn-secondary mr-2" type="button" @click="agregar_opcion">{{ !tmp_opcion.index ? 'Agregar' : 'Actualizar' }}</button>
                      <button class="btn btn-danger" type="button" @click="cancelar_form_opcion">Cancelar</button>
                    </td>
                  </tr>
                  <tr v-for="(opcion, index) in tmp_catalogo.opciones">
                    <td>{{ opcion.nombre }}</td>
                    <td>{{ opcion.valor }}</td>
                    <td>{{ opcion.estatus == 1 ? 'Activo' : 'Inactivo' }}</td>
                    <td>
                      <button class="btn btn-secondary mr-2" type="button" @click="editar_opcion(opcion,index)"><i class="fa-regular fa-pen-to-square"></i></button>
                      <button class="btn btn-secondary mr-2" type="button" @click="quitar_opcion(index)"><i class="fa-solid fa-trash-can"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="row">
            <div class="col-sm-12 text-right">
              <button class="btn btn-primary mr-2" type="button" @click="guardar_catalogo">Guardar</button>
              <button class="btn btn-danger" type="button" @click="$emit('close')">Cancelar</button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal';

import api from '@/apps/analyticpoint/api/comite/catalogos';

export default {
  components: {
    Modal
  }
  ,props: {
    catalogo: {
      type: Object
    }
  },
  data() {
    return {
      tmp_catalogo: {
        nombre: null,
        codigo: null,
        descripcion: null,
        estatus: 1,
        opciones: []
      },
      tmp_opcion: {
        nombre: null,
        valor: null,
        estatus: 1
      },
      agragar_opcion_form: false
    }
  },
  mounted() {
    if (this.catalogo)
      this.tmp_catalogo = this.$helper.clone(this.catalogo);

    if (this.tmp_catalogo.id)
      this.obtener_catalogo();
  },
  methods: {
    async obtener_catalogo() {
      try {
        this.tmp_catalogo = (await api.obtener_catalogo(this.tmp_catalogo.id, false)).data;
      }catch(e) {
        this.$log.error(e);
        this.$helper.showAxiosError(e,'Error');
      }
    },
    agregar_opcion() {
      if (!this.tmp_opcion.index)
        this.tmp_catalogo.opciones.push(this.tmp_opcion);
      else {
        let opciones = []
        for(let i=0; i<this.tmp_catalogo.opciones.length; i++) {
          if (i==this.tmp_opcion.index)
            opciones.push(this.tmp_opcion);
          else
            opciones.push(this.tmp_catalogo.opciones[i]);
        }

        this.tmp_catalogo.opciones = opciones;
      }
      this.cancelar_form_opcion();
    },
    editar_opcion(opcion, index) {
      this.tmp_opcion = this.$helper.clone(opcion);
      this.tmp_opcion.index = index;
      this.agragar_opcion_form = true;
    },
    quitar_opcion(index) {
      let opciones = [];

      for (let i=0; i<this.tmp_catalogo.opciones.length; i++) {
        if (i != index)
          opciones.push(this.tmp_catalogo.opciones[i]);
      }

      this.tmp_catalogo.opciones = opciones;
    },
    cancelar_form_opcion() {
      this.tmp_opcion = {
        nombre: null,
        valor: null,
        estatus: 1
      }
      this.agragar_opcion_form = false;
    },
    async guardar_catalogo() {
      try {
        let res = '';
        if (!this.tmp_catalogo.id)
          res = (await api.crear_catalogo(this.tmp_catalogo)).data;
        else
          res = (await api.actualizar_catalogo(this.tmp_catalogo.id, this.tmp_catalogo)).data;

        this.$helper.showMessage('Exito','Se guardo el catálogo con exito','success','alert');
        this.$log.info('res', res);
        this.$emit('update');
      }catch(e) {
        this.$log.error(e);
        this.$helper.showAxiosError(e,'Error');
      }
    }
  }
}
</script>

<style lang="scss">
.catalogo_opciones{
  max-height: 300px;
  overflow: hidden;
  overflow-y: auto;

  table {
    width: 100%;

    thead {
      tr {
        background-color: hsl(0, 0%, 30%);

        th {
          padding: 10px 15px;
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 10px 15px;
        }
      }

      tr:nth-child(2n+0) {
        background-color: hsl(0, 0%, 90%);
      }
    }
  }
}
</style>